<template>
  <v-dialog
    v-model="internalShowing"
    transition="false"
    fullscreen
    scrollable
  >
    <b10-toolbar
      title="Añadir material al plano"
    >
      <v-btn
        slot="right"
        icon
        @click.stop="clickSubmitAddForm($refs.form)"
      >
        <v-icon>{{ $vuetify.icons.values.check }}</v-icon>
      </v-btn>
    </b10-toolbar>
    <v-card>
      <div
        class="pt-15 ml-2"
      >
        <b10-autocomplete
          v-model="form.idsubsis"
          :items="subsistemas"
          item-value="idsubsis"
          item-text="descripcion"
          label="Subsistema"
          clearable
          @change="subsisSelected"
        />
        <v-checkbox
          v-model="marcarTodos"
          :label="'Marcar todos'"
          @change="changeMarcarTodos"
        />
        <v-divider />
        <v-list
          v-for="(material, index) in materialesSistemaForm"
          :key="index"
          dense
        >
          <v-checkbox
            v-model="form[material.idmaterial_sistema]"
            :label="material.descripcion"
            :hint="$online.materialSistema.resumenFichaTecnica(material).join(' - ')"
            persistent-hint
          />
        </v-list>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import internalShowingMixin from '@/mixins/internalShowingMixin'
import formMixin from '@/mixins/formMixin'
import _ from '@/utils/lodash'

export default {
  components: {
  },
  mixins: [internalShowingMixin, formMixin],
  props: {
    idsistema: {
      type: [String, Number],
      default: null,
    },
    subsistemas: {
      type: Array,
      default: () => [],
    },
    materialesSistemaForm: {
      type: Array,
      default: () => [],
    },
    idsubsis: {
      type: [String, Number],
      default: null,
    }
  },
  data () {
    return {
      form: {
        idsubsis: null,
      },
      formRules: {
        idsubsis: { required: true },
      },
      marcarTodos: false,
    }
  },
  watch: {
    async idsubsis (newValue) {
      if (newValue) {
        this.$set(this.form, 'idsubsis', newValue)
        await this.subsisSelected(newValue)
      }
    }
  },
  async created () {
    await this.loadForm()
  },
  methods: {
    async subsisSelected (idsubsis) {
      this.$emit('load-material-sistema-form', { idsubsis })
    },
    cleanForm () {
      this.$set(this.form, 'idsubsis', null)
    },
    async clickSubmitAddForm () {
      this.internalShowing = false
      // Obtener las claves de form que tienen un valor true
      const materialesSelecionados = _.pickBy(this.form, (value) => value === true)
      // Filtrar los elementos de materialesSistemaForm cuyo idmaterial_sistema está en las claves seleccionadas
      const elementosSeleccionados = _.filter(this.materialesSistemaForm, (elemento) =>
        _.has(materialesSelecionados, elemento.idmaterial_sistema.toString())
      )
      this.$emit('materiales-add-form-data', { items: elementosSeleccionados })
      this.cleanForm()
    },
    changeMarcarTodos(value) {
      this.marcarTodos = value
      this.materialesSistemaForm.forEach((material) => {
        this.form[material.idmaterial_sistema] = value
      })
    },
  },
}
</script>