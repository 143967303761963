<template>
  <v-menu
    v-model="internalShowing"
    :position-x="formattedItem.posicion_plano_x"
    :position-y="formattedItem.posicion_plano_y"
    :offset-x="true"
    :offset-y="true"
    :close-on-click="false"
    :close-on-content-click="false"
    max-width="300px"
    @input="clickClose"
  >
    <v-card>
      <v-card-title>
        {{ formattedItem.title }}
      </v-card-title>
      <v-card-text
        v-for="dato in formattedItem.fichaTecnica"
        :key="dato.idmaterial_sistema"
      >
        <span
          v-html="dato"
        />
        <slot />
      </v-card-text>
      <v-card-actions>
        <v-btn
          v-if="readOnly"
          color="info"
          dark
          class="flex-grow-1"
          @click.stop="clickVerMaterialSistema()"
        >
          <v-icon
            left
          >
            {{ $vuetify.icons.values.materialAfectado }}
          </v-icon> Ver material
        </v-btn>
        <v-btn
          v-else
          color="error"
          dark
          class="flex-grow-1"
          @click.stop="clickBorrarMaterialSistema()"
        >
          <v-icon
            left
          >
            {{ $vuetify.icons.values.delete }}
          </v-icon> Borrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import internalShowingMixin from '@/mixins/internalShowingMixin'
import _ from '@/utils/lodash'

export default {
  mixins: [internalShowingMixin],
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    readOnly: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    formattedItem () {
      if (!_.isEmpty(this.item)) {
        const item = _.cloneDeep(this.item)
        item.title = item.descripcion
        item.fichaTecnica = this.$online.materialSistema.resumenFichaTecnica(item)
        return item
      } else {
        return {}
      }
    },
  },
  methods: {
    clickClose () {
      this.$emit('close-menu-material', this.internalShowing )
    },
    clickVerMaterialSistema () {
      this.$emit('ver-material', { item: this.item })
    },
    async clickBorrarMaterialSistema () {
      this.$emit('delete', { title: this.item.descripcion, idmaterialSistema: this.item.idmaterial_sistema })
    }
  },
}
</script>
