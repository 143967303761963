var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b10-base',[_c('b10-toolbar',{attrs:{"title":_vm.title,"options":_vm.toolbarOptions},on:{"click-option":_vm.clickToolbarOption}},[(!_vm.readOnly)?_c('v-btn',{attrs:{"slot":"right","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.clickSubmit()}},slot:"right"},[_c('v-icon',[_vm._v(_vm._s(_vm.$vuetify.icons.values.check))])],1):_vm._e()],1),_c('b10-page-content',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.imageIsLoaded),expression:"imageIsLoaded"}],staticClass:"canvas-container",attrs:{"scrolling":"touch"}},[_c('material-sistema-plano-imagen',{staticClass:"pa-3",attrs:{"item":_vm.formattedItem},on:{"image-loaded":_vm.imageLoaded}}),_c('canvas',_vm._g({ref:"MaterialSistemaCanvas",staticClass:"material-sistema-canvas"},!_vm.readOnly ? {
          mousedown: _vm.handleMouseDown,
          mousemove: _vm.handleMouseMove,
          mouseup: _vm.handleMouseUp,
          touchstart: _vm.handleTouchStart,
          touchmove: _vm.handleTouchMove,
          touchend: _vm.handleTouchEnd,
        } : {
          mousedown: _vm.handleMouseDownReadOnly,
        })),(_vm.readOnly)?_c('b10-fab-button',{attrs:{"icon":_vm.$vuetify.icons.values.edit},on:{"click":_vm.clickEdit}}):_vm._e(),_c('material-sistema-plano-menu',{attrs:{"showing":_vm.showingDialogs.showingMenuMaterial,"item":_vm.elementoArrastrado,"read-only":_vm.readOnly},on:{"delete":_vm.clickDeleteConfirm,"ver-material":_vm.clickVerMaterial,"close-menu-material":_vm.closeShowingMenuMaterial}}),_c('material-sistema-plano-add',{attrs:{"showing":_vm.showingDialogs.materialPlanoAdd,"idsistema":_vm.routeParams.idsistema,"subsistemas":_vm.subsistemas,"idsubsis":_vm.idsubsis,"materiales-sistema-form":_vm.materialesSistemaForm},on:{"update:showing":function($event){return _vm.$set(_vm.showingDialogs, "materialPlanoAdd", $event)},"materiales-add-form-data":_vm.materialesAddFormData,"load-material-sistema-form":_vm.loadMaterialSistemaForm}}),_c('material-sistema-plano-legend',{attrs:{"showing":_vm.showingDialogs.legends,"colores-tipo-subfamilia":_vm.coloresTipoSubfamilia},on:{"update:showing":function($event){return _vm.$set(_vm.showingDialogs, "legends", $event)}}}),(!_vm.readOnly)?_c('b10-fab-button',{on:{"click":_vm.clickAddMaterial}}):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }